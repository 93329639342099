angular.module('LeasePilot').controller('FileUploadController', [
  '$scope',
  '$anchorScroll',
  '$q',
  'Upload',
  'AttachmentService',
  'DocumentService',
  'ApiService',
  '$http',
  function(
    $scope,
    $anchorScroll,
    $q,
    Upload,
    AttachmentService,
    DocumentService,
    ApiService,
    $http,
  ) {
    var docFileType = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    var imgFileType = ['image/*'];
    var pdfFileType = ['application/pdf', 'application/x-pdf'];
    var allFileTypes = _.union(docFileType, imgFileType, pdfFileType);

    $scope.supportedFiles = allFileTypes.join();

    $scope.init = function(refLeaseProperty, prefix, elementId) {
      if (!$scope.uploader) {
        $scope.uploader = {};
      }

      // This holds the property in the lease we're changing
      $scope.uploader.refLeaseProperty = refLeaseProperty;
      // This holds the prefix of the field on the backend
      $scope.uploader.prefix = prefix;
      // This is the ID of the element in the HTML to scroll to
      $scope.uploader.element = elementId;
    };

    $scope.getFileName = function(fileURL) {
      if (fileURL) {
        let filename;
        filename = fileURL.split('/').pop();
        filename = filename.split('?').shift();
        return filename;
      } else {
        return 'No File Chosen';
      }
    };

    $scope.uploadFiles = function(file) {
      if (!file || !$scope.lease.id) {
        return;
      }

      $scope.uploading = true;
      if (docFileType.indexOf(file.type) !== -1) {
        ApiService.documentToPdf(file).then(function(res) {
          var fileName = file.name
            .replace('.docx', '.pdf')
            .replace('.doc', '.pdf');
          file = new File([res.data], fileName, {
            type: 'application/pdf',
            lastModified: Date.now(),
          });
          handleFile(file);
        });
      } else {
        handleFile(file);
      }

      function handleFile(file) {
        var fileSize = file.size / 1024 / 1024;

        if (fileSize > 20) {
          alert('File size over 20MB cannot be attached');
          $scope.uploading = false;
        } else if (file.$error) {
          alert(
            'Oops... something went wrong trying to attach the file, please wait a few seconds and try again. (error code: ' +
              file.$error +
              ')',
          );
          $scope.uploading = false;
        } else {
          $scope.f = file;
          if (file && !file.$error) {
            function getFields(file, prefix) {
              var fields = {};

              fields['document[id]'] = $scope.lease.id;
              fields['document[' + prefix + '_width]'] = file.width;
              fields['document[' + prefix + '_height]'] = file.height;

              return fields;
            }

            function error() {
              $scope.uploading = false;
            }

            var prefix = $scope.uploader.prefix;

            var resourceUrl = pluralize($scope.lease.type).toLowerCase();

            file.upload = Upload.upload({
              url: '/api/' + resourceUrl + '/' + $scope.lease.id,
              file: file,
              fields: getFields(file, prefix),
              method: 'put',
              fileFormDataName: 'document[' + prefix + ']',
            });

            file.upload.then(function() {
              DocumentService.get({
                id: $scope.lease.id,
                type: $scope.lease.type,
              }).then(function(newLease) {
                var camelCasePrefix = _.camelCase($scope.uploader.prefix);
                // Update the URL of the attachment
                setTimeout(function() {
                  newLease[camelCasePrefix].url =
                    newLease[camelCasePrefix].url + '?timestamp=' + new Date().getTime();
                  $scope.lease[camelCasePrefix] = newLease[camelCasePrefix];
                  // set last updated lease to be the current
                  $scope.lastUpdatedLease = JSON.stringify($scope.lease);
                }, 0);

                // Lets wait 'till the attachment is transformed and loaded
                AttachmentService.attachments[camelCasePrefix] = [];

                var result = AttachmentService.getFile(
                  newLease[camelCasePrefix],
                  camelCasePrefix,
                );

                // `getFile` can return a promise in-case it converted PDF to a set of images
                // or nothing if it were just an image
                if (result && result.then) {
                  result.then(function(result) {
                    $q.all(result.list)
                      .then($scope.success)
                      .catch(error);
                  });
                } else {
                  $scope.success();
                }
              });
            });
          }
        }
      }
    };

    $scope.removeFile = function() {
      if (!$scope.lease.id) {
        return;
      }
      var prefix = $scope.uploader.prefix;
      $http({
        method: 'POST',
        url: '/api/leases/' + $scope.lease.id + '/remove_attachment',
        data: {
          id: $scope.lease.id,
          prefix,
        },
      }).then(result => {
        var camelCasePrefix = _.camelCase(prefix);
        AttachmentService.attachments[camelCasePrefix] = [];
        $scope.lease[camelCasePrefix] = null;
        $scope.success();
      });
    };

    $scope.success = function() {
      $scope.update();
      $scope.updateCurrent($scope.uploader.refLeaseProperty);
      $scope.uploading = false;
    };
  },
]);
